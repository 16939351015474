/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import FilterArtes from 'components/FilterArtes';
import SingleSelect from 'components/_base/SelectSingle';
import CardArtes from 'components/CardArtes';
import ModalSummary from 'components/ModalSummary';
import ModalSolicitarNovamente from 'components/ModalSolicitarNovamente';
import api from 'api';
import { GET_LIST_BANCO_ARTE } from 'services/BancoArte';
import Lightbox from 'react-image-lightbox';

import './styles.scss';

const qs = require('qs');

const ContentBancoArtes = () => {
  const [solicitarNovamenteId, setSolicitarNovamenteId] = useState(0);
  const [resumoId, setResumoId] = useState(0);
  const { showLoader } = useContext(Context);
  const [page, setPage] = useState(1);
  const [dataCardArtes, setDataCardArtes] = useState();
  const [filterParams, setFilterParams] = useState({
    solicitacaoId: '',
    MaterialComunicacaoIds: [],
    CampanhaIds: [],
    codigo: '',
  });
  const [lightboxStatus, setLightboxStatus] = useState({
    srcUrl: '',
    isOpen: false,
  });

  useEffect(() => {
    (async () => {
      showLoader(true);
      const data = await GET_LIST_BANCO_ARTE({
        PageNumber: page,
        PageSize: 20,
        ...filterParams,
      });
      setDataCardArtes(data.value);
      showLoader(false);
    })();
  }, []);

  async function changePage(page) {
    showLoader(true);
    setPage(page);
    const data = await GET_LIST_BANCO_ARTE({
      PageNumber: page,
      PageSize: 20,
      ...filterParams,
    });
    setDataCardArtes(data.value);
    showLoader(false);
  }

  const filterArtes = async (
    solicitacaoid,
    MaterialComunicacaoIdsSelecionados,
    CampanhaIdsSelecionadas,
    codigo
  ) => {
    setFilterParams({
      solicitacaoid: solicitacaoid,
      MaterialComunicacaoIds: MaterialComunicacaoIdsSelecionados,
      CampanhaIds: CampanhaIdsSelecionadas,
      codigo: codigo,
    });

    let params = {
      PageNumber: 1,
      PageSize: 20,
      MaterialComunicacaoIds: MaterialComunicacaoIdsSelecionados,
      CampanhaIds: CampanhaIdsSelecionadas,
      Solicitacaoid: solicitacaoid,
      Codigo: codigo,
    };

    showLoader(true);

    const data = await GET_LIST_BANCO_ARTE(params);
    setDataCardArtes(data.value);

    showLoader(false);
  };

  const onShowResumo = (solicitacaoId) => {
    (async () => {
      setResumoId(solicitacaoId);
    })();
  };

  const onCloseResumo = (solicitacaoId) => {
    (async () => {
      setResumoId(0);
    })();
  };

  const handleSolicitarNovamente = (solicitacaoId) => {
    setResumoId(0);
    setSolicitarNovamenteId(solicitacaoId);
  };

  const closeSolicitarNovamente = () => {
    setSolicitarNovamenteId(0);
  };

  return (
    <div className="wrapper-banco-Artes">
      <TitlePages title="Banco de Artes" />
      <div className="content">
        <div className="column1-artes col-lg-3">
          <FilterArtes filtrar={filterArtes} />
        </div>
        <div
          className="column2-artes float-left col-lg-9"
          style={{ position: 'static' }}
        >
          <div className="order">
            <div className="select col-lg-4 float-rigth">
              <SingleSelect />
            </div>
          </div>

          <div className="cards">
            <div
              className=" col-lg-12 cards-align "
              style={{ position: 'static' }}
            >
              <CardArtes
                {...dataCardArtes}
                pageData={dataCardArtes}
                changePage={changePage}
                onClickResumo={onShowResumo}
                handleSolicitarNovamente={handleSolicitarNovamente}
                lightboxStatus={lightboxStatus}
                setLightboxStatus={setLightboxStatus}
              />
            </div>
          </div>
        </div>
        {lightboxStatus.isOpen && (
          <Lightbox
            mainSrc={lightboxStatus.srcUrl}
            imageLoadErrorMessage="Erro ao carregar imagem"
            onCloseRequest={() => {
              setLightboxStatus({
                ...lightboxStatus,
                isOpen: false,
              });
              document.body.style.overflow = '';
            }}
          />
        )}
      </div>
      <ModalSummary
        solicitacaoId={resumoId}
        onClose={onCloseResumo}
        handleSolicitarNovamente={handleSolicitarNovamente}
        displayButtonSolicitarNovamente={true}
      />
      <ModalSolicitarNovamente
        solicitacaoId={solicitarNovamenteId}
        onClose={closeSolicitarNovamente}
      />
    </div>
  );
};
export default ContentBancoArtes;
