import api from 'api';

const PATH = '/Consulta';
const qs = require('qs');

const GET_RESUMO_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-resumo-solicitacao/${solicitacaoId}`
  );
  return data;
};
const GET_RESUMO_MODEL = async (solicitacaoId) => {
  const { data } = await api.get(`${PATH}/get-resumo-model/${solicitacaoId}`);
  return data;
};
const GET_LIST_SOLICITACAO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-solicitacao-por-filtro`,
    {
      params: filtros,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
  return data;
};
const GET_LIST_HISTORICO_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-list-historico-solicitacao/${solicitacaoId}`
  );
  return data;
};
const GET_LIST_SOLICITACAO_USUARIO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-solicitacao-usuario`, {
    params: filtros,
  });
  return data;
};

export {
  GET_RESUMO_SOLICITACAO,
  GET_RESUMO_MODEL,
  GET_LIST_SOLICITACAO,
  GET_LIST_HISTORICO_SOLICITACAO,
  GET_LIST_SOLICITACAO_USUARIO,
};
