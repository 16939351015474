import React from 'react';
import { Switch } from 'react-router-dom';

import PageNotFound from 'pages/PaginaNaoEncontrada';
import Login from 'pages/Login';
import RecoverPassword from 'pages/RecoverPassword/index';
import RecoveryEmailSent from 'pages/RecoverPassword/EmailRecuperacaoEnviado/index';
import PasswordChanged from 'pages/RecoverPassword/SenhaAlterada/index';
import NewPassword from 'pages/RecoverPassword/NovaSenha/index';
import Home from 'pages/Home';
import ChooseTheCustomer from 'pages/EscolhaCliente';
import SelectMaterials from 'pages/SelecionarMateriais';
import DeliveryMaterials from 'pages/EntregaMateriais';
import Query from 'pages/Consulta';
import Pendencias from 'pages/Pendencias';
import BancoImagens from 'pages/BancoImagens';
import BancoArtes from 'pages/BancoArtes';
import DadosUsuario from 'pages/DadosUsuario';
import Attendance from 'pages/Atendimento';
import Called from 'pages/Chamado';
import CalledSent from 'pages/ChamadoEnviado';
import CalledFinished from 'pages/ChamadoFinalizado';
import Contato from 'pages/Contato';
import FollowCalled from 'pages/AcompanharChamado';
import ChatCalled from 'pages/ChamadoChat';
import Draft from 'pages/Rascunho';
import SolicitacaoFluxoStatus from 'pages/SolicitacaoFluxo/Status';
import SolicitacaoFluxoEnviarArte from 'pages/SolicitacaoFluxo/EnviarArte';
import SolicitacaoFluxoConfirmarEnvio from 'pages/SolicitacaoFluxo/ConfirmarEnvio';
import SolicitacaoFluxoAprovarArte from 'pages/SolicitacaoFluxo/AprovarArte';
import SolicitacaoFluxoDefinirOrcamento from 'pages/SolicitacaoFluxo/DefinirOrcamento';
import SolicitacaoFluxoAprovarOrcamento from 'pages/SolicitacaoFluxo/AprovarOrcamento';
import SolicitacaoFluxoOrcamentoReprovado from 'pages/SolicitacaoFluxo/OrcamentoReprovado';
import SolicitacaoFluxoRevisao from 'pages/SolicitacaoFluxo/Revisao';
import DeliveryDigitalMaterials from 'pages/EntregaMateriaisDigitais';
import PedidoMateriais from 'pages/PedidoMateriais';
// import PedidoCampanha from 'pages/PedidoCampanha';
import AlterarSolicitacao from 'pages/AlterarSolicitacao';
import ResumoPedido from 'pages/ResumoPedido';
import HistoricoSolicitacoes from 'pages/HistoricoSolicitacoes';
// import ArtesFacil from "pages/ArtesFacil";
import ValidacaoDadosUsuario from 'pages/ValidacaoDadosUsuario';
import Relatorios from 'pages/Relatorios';
import RelatoriosOld from 'pages/Relatorios-Old';
import AdminUsuarios from 'pages/AdminUsuarios';
import AdminLogar from 'pages/AdminLogar';
import AcessoRestrito from 'pages/AcessoRestrito';
import ErroServidor from 'pages/ErroServidor';
import Acesso from 'pages/Acesso';
import CustomRoute from './CustomRoute';

const Routes = () => {
  return (
    <Switch>
      <CustomRoute exact isPrivate path="/" component={Home} />
      <CustomRoute exact isPrivate path="/admin" component={AdminUsuarios} />
      <CustomRoute
        exact
        isPrivate
        path="/admin/logar/:usuarioId?"
        component={AdminLogar}
      />
      <CustomRoute path="/acesso" component={Acesso} />
      <CustomRoute path="/login" component={Login} />
      <CustomRoute path="/acesso-restrito" component={AcessoRestrito} />
      <CustomRoute path="/erro" component={ErroServidor} />
      <CustomRoute path="/recuperar-senha" component={RecoverPassword} />
      <CustomRoute
        path="/email-recuperacao-enviado"
        component={RecoveryEmailSent}
      />
      <CustomRoute path="/senha-alterada" component={PasswordChanged} />
      <CustomRoute path="/novasenha" component={NewPassword} />
      <CustomRoute path="/contato" component={Contato} />
      <CustomRoute
        path="/atualizar-dados"
        isPrivate
        component={ValidacaoDadosUsuario}
      />
      <CustomRoute
        isPrivate
        path="/escolha-o-cliente/:id?"
        component={ChooseTheCustomer}
      />
      <CustomRoute
        isPrivate
        path="/selecione-os-materiais/:rascunhoId"
        component={SelectMaterials}
      />
      <CustomRoute
        isPrivate
        path="/entrega-de-materiais"
        component={DeliveryMaterials}
      />
      <CustomRoute
        isPrivate
        path="/entrega-de-materiais-digitais/:rascunhoId"
        component={DeliveryDigitalMaterials}
      />
      <CustomRoute isPrivate path="/consulta" component={Query} />

      <CustomRoute
        isPrivate
        path="/banco-de-imagens"
        component={BancoImagens}
      />
      <CustomRoute isPrivate path="/banco-de-artes" component={BancoArtes} />
      <CustomRoute isPrivate path="/meus-dados" component={DadosUsuario} />
      <CustomRoute isPrivate path="/atendimento" component={Attendance} />
      <CustomRoute isPrivate path="/chamado" component={Called} />
      <CustomRoute isPrivate path="/chamado-enviado" component={CalledSent} />
      <CustomRoute
        isPrivate
        path="/chamado-finalizado"
        component={CalledFinished}
      />
      <CustomRoute
        isPrivate
        path="/acompanhar-chamado"
        component={FollowCalled}
      />
      <CustomRoute isPrivate path="/chat" component={ChatCalled} />
      <CustomRoute isPrivate path="/rascunho" component={Draft} />
      <CustomRoute isPrivate path="/pendencias" component={Pendencias} />

      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/status"
        component={SolicitacaoFluxoStatus}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/enviararte"
        component={SolicitacaoFluxoEnviarArte}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/confirmar-envio"
        component={SolicitacaoFluxoConfirmarEnvio}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/aprovararte"
        component={SolicitacaoFluxoAprovarArte}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/definirorcamento"
        component={SolicitacaoFluxoDefinirOrcamento}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/aprovarorcamento"
        component={SolicitacaoFluxoAprovarOrcamento}
      />
      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/orcamentoreprovado"
        component={SolicitacaoFluxoOrcamentoReprovado}
      />

      <CustomRoute
        isPrivate
        path="/solicitacao-fluxo/revisao"
        component={SolicitacaoFluxoRevisao}
      />

      <CustomRoute
        isPrivate
        path="/novo-pedido/materiais/:id?"
        component={PedidoMateriais}
      />
      <CustomRoute
        isPrivate
        path="/pedido-materiais/:pedidoId?/:tabPedido?/:tabSolicitacao?"
        component={PedidoMateriais}
      />
      {/* <CustomRoute
        isPrivate
        path="/pedido-campanha/:pedidoId?/:tabPedido?/:tabSolicitacao?"
        component={PedidoCampanha}
      /> */}
      <CustomRoute
        isPrivate
        path="/alterar-solicitacao/:solicitacaoId?/:tab?"
        component={AlterarSolicitacao}
      />
      <CustomRoute
        isPrivate
        path="/resumo-pedido/:pedidoId?"
        component={ResumoPedido}
      />
      <CustomRoute
        isPrivate
        path="/historico-solicitacoes"
        component={HistoricoSolicitacoes}
      />
      {/* <CustomRoute
        isPrivate
        path="/artes-facil/:pedidoId?/:tabPedido?"
        component={ArtesFacil}
      /> */}
      <CustomRoute isPrivate path="/relatorios" component={Relatorios} />
      <CustomRoute isPrivate path="/relatorios-old" component={RelatoriosOld} />
      <CustomRoute path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
