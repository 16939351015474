/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Context } from 'context/AuthContext';
import { GET_LIST_SOLICITACAO_USUARIO } from 'services/Consulta';
import { CREATE_RASCUNHO_COPIA_SOLICITACAO } from 'services/Solicitacao';
import { Button } from 'react-bootstrap';

import './styles.scss';

const ContentHistoricoPedidos = () => {
  const { showLoader } = useContext(Context);
  const [model, setModel] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      showLoader(true);
      const data = await GET_LIST_SOLICITACAO_USUARIO();
      setModel(data.value);
      showLoader(false);
    })();
  }, []);

  const solicitar = (solicitacaoId) => {
    (async () => {
      showLoader(true);
      const response = await CREATE_RASCUNHO_COPIA_SOLICITACAO(solicitacaoId);

      if (response?.value) {
        const rascunho = response.value;
        history.push(`/pedido-materiais/${rascunho.rascunhoId}`);
      }

      showLoader(false);
    })();
  };

  return (
    <>
      <div className="table-historico-solicitacoes">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Cliente</th>
                <th style={{ width: '20%' }}>Tipo de Material</th>
                <th>Etapa Atual</th>
                <th>Pendente Para</th>
                <th style={{ width: '230px' }} />
              </tr>
            </thead>
            <tbody>
              {(model || []).map((item, idx) => {
                return (
                  <tr
                    key={idx}
                    className={
                      item.solicitacaoFinalizada ? 'finalizado' : 'andamento'
                    }
                  >
                    <td>{item.solicitacaoId}</td>
                    <td>{item.razaoSocial}</td>
                    <td>{item.materialComunicacao}</td>
                    <td>{item.etapa}</td>
                    <td>{item.usuarioPendencia ?? '-'}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="mb-3"
                        onClick={() => solicitar(item.solicitacaoId)}
                      >
                        SOLICITAR NOVAMENTE
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ContentHistoricoPedidos;
