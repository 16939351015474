/* eslint-disable */
import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import {
  cadastroItemKeys,
  entregaTipoNomes,
  checkUrlImage,
  getStringFromDate,
  getStringFromPrice,
} from '../../_constants';

const ResumoMaterial = (props) => {
  const { aprovador, aprovadorMidiasDigitais } = props;
  let solicitacao = props.solicitacao;
  let loja = props.loja || {};
  let entregaDigital = props.entregaDigital || {};
  let entregaCentroDistribuicao = props.entregaCentroDistribuicao || {};
  let entregaLojasParticipantes = props.entregaLojasParticipantes || [];
  let entregaOutro = props.entregaOutro || {};
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;
  let accordionKey = '' + props.index;

  solicitacao.solicitacaoId = solicitacao.solicitacaoId
    ? solicitacao.solicitacaoId
    : (pedidoCriado.solicitacoes || []).find(
        (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId
      )?.solicitacaoId || 0;

  let cliente = loja;

  let solicitacaoDados = solicitacao.solicitacaoDados || {};

  let material = []
    .concat(
      ...(entities.tipoMateriais || []).map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao
      )
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);

  let cadastroItens =
    (entities.materiaisCadastroItens || []).find(
      (x) => x.materialComunicacaoId === solicitacao.materialComunicacaoId
    )?.cadastroItens || [];

  let nomeFantasia =
    (solicitacaoDados.nomeFantasia || '').length > 0
      ? solicitacaoDados.nomeFantasia
      : cliente.nomeFantasia;

  let motivo =
    (entities.motivos || []).find(
      (motivo) => motivo.motivoAcaoId === solicitacaoDados.motivoAcaoId
    ) || {};

  let outroMotivo = motivo.habilitaDescricao
    ? ' - ' + (solicitacaoDados.outroMotivo || '')
    : '';

  let cidades = [].concat(
    ...(entities.estados || []).map((estado) => estado.cidades)
  );

  let tipoEntrega =
    (entities.tipoEntregas || []).find((t) => {
      return t.entregaTipoId === solicitacao.entregaTipoId;
    }) || {};

  let solicitacaoTamanhos = solicitacao.solicitacaoTamanhos || [];

  let solicitacaoEspecificacoes = solicitacao.solicitacaoEspecificacoes || [];

  let unidadeMedida = material?.unidadeMedida?.toLowerCase();

  let extensaoArquivo =
    (entities.extensoes || []).find(
      (item) => item.nome === solicitacaoDados.extensaoArquivo
    ) || {};

  let tempoVideo =
    (entities.tempos || []).find(
      (item) => item.tempoVideoId === solicitacaoDados.tempoVideoId
    ) || {};

  let extensaoOutro = extensaoArquivo.habilitaDescricao
    ? ' - ' + (solicitacaoDados.extensaoOutro || '')
    : '';

  let possuiLogo = solicitacaoDados.possuiLogo ? 'Sim' : 'Não';

  let possuiRodape = solicitacaoDados.possuiRodape ? 'Sim' : 'Não';

  let layoutArte =
    ((entities.clienteLayouts || {}).layoutArtes || []).find(
      (layout) => layout.layoutArteId === solicitacaoDados.layoutArteId
    ) || {};

  let solicitacaoRodapes = (solicitacao.solicitacaoRodapes || []).map(
    (solicitacaoRodape) => {
      return {
        ...solicitacaoRodape,
        cidade: cidades.find(
          (cidade) => cidade.cidadeId === solicitacaoRodape.cidadeId
        ),
      };
    }
  );

  let solicitacaoProdutos = solicitacao.solicitacaoProdutos || [];

  entregaOutro = {
    ...entregaOutro,
    cidade: cidades.find(
      (cidade) => cidade.cidadeId === entregaOutro?.cidadeId
    ),
  };

  const renderInformacoes = () => {
    return (
      <div className="resumo-informacoes">
        <h4>Informações</h4>
        <table className="table table-striped">
          <tbody>
            {solicitacao.solicitacaoReferenciaId && (
              <tr>
                <th>Solicitacao de Referência</th>
                <td>{solicitacao.solicitacaoReferenciaId}</td>
              </tr>
            )}
            <tr>
              <th>Cliente</th>
              <td>{cliente.razaoSocial}</td>
            </tr>
            <tr>
              <th>CNPJ</th>
              <td>{cliente.cnpj}</td>
            </tr>
            <tr>
              <th>Nome Fantasia</th>
              <td>{nomeFantasia}</td>
            </tr>

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Veiculacao
            ) !== -1 && (
              <tr>
                <th>Veiculação</th>
                <td>
                  {getStringFromDate(solicitacaoDados.dataVeiculacaoInicio)} -{' '}
                  {getStringFromDate(solicitacaoDados.dataVeiculacaoFim)}
                </td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_DataEnvioArte
            ) !== -1 && (
              <tr>
                <th>Data para receber o Layout</th>
                <td>{getStringFromDate(solicitacaoDados.dataEnvioArte)}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Motivo
            ) !== -1 && (
              <tr>
                <th>Motivo da ação</th>
                <td>
                  {motivo.nome || ''} {outroMotivo}
                </td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key ===
                cadastroItemKeys.Dados_QuantidadeLojasParticipantes
            ) !== -1 && (
              <tr>
                <th>Quantidade de Lojas Participantes</th>
                <td>{solicitacaoDados.quantidadeLojasParticipantes}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key ===
                cadastroItemKeys.Dados_QuantidadeImpressosLoja
            ) !== -1 && (
              <tr>
                <th>Quantidade de Impressos por Loja</th>
                <td>{solicitacaoDados.quantidadeImpressosLoja}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_QuantidadePaginas
            ) !== -1 && (
              <tr>
                <th>Quantidade de Páginas</th>
                <td>{solicitacaoDados.quantidadePaginas}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_QuantidadePaginas
            ) !== -1 && (
              <tr>
                <th>Site</th>
                <td>{solicitacaoDados.quantidadePaginas}</td>
              </tr>
            )}

            {(cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Tamanho_Unico
            ) !== -1 ||
              cadastroItens.findIndex(
                (cadastroItem) =>
                  cadastroItem.key === cadastroItemKeys.Dados_Tamanhos_Multi
              ) !== -1) && (
              <tr>
                <th>Medidas (Largura x Altura)</th>
                <td>
                  {solicitacaoTamanhos.map((solicitacaoTamanho, index) => (
                    <span key={index} className="d-block">
                      {solicitacaoTamanho.largura} {unidadeMedida}{' '}
                      <span className="m-2">X</span> {solicitacaoTamanho.altura}{' '}
                      {unidadeMedida}
                    </span>
                  ))}
                </td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Especificacao_Multi
            ) !== -1 && (
              <tr>
                <th>Especificações </th>
                <td>
                  {solicitacaoEspecificacoes.map(
                    (solicitacaoEspecificacao, index) => (
                      <span key={index} className="d-block">
                        {'Descrição: '}
                        {solicitacaoEspecificacao.descricao}
                        <span className="m-2"></span>
                        {'Quantidade: '}
                        {solicitacaoEspecificacao.quantidade}
                      </span>
                    )
                  )}
                </td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_ExtensaoArquivo
            ) !== -1 && (
              <tr>
                <th>Tipo de Arquivo</th>
                <td>
                  {extensaoArquivo.nome || ''} {extensaoOutro}
                </td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Tempo
            ) !== -1 && (
              <tr>
                <th>Tempo (segundos)</th>
                <td>{tempoVideo.descricao || ''}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Site
            ) !== -1 && (
              <tr>
                <th>Site</th>
                <td>{solicitacaoDados.site}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Facebook
            ) !== -1 && (
              <tr>
                <th>Facebook</th>
                <td>{solicitacaoDados.facebook}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Instagram
            ) !== -1 && (
              <tr>
                <th>Instagram</th>
                <td>{solicitacaoDados.instagram}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_TelefoneContato
            ) !== -1 && (
              <tr>
                <th>Telefone Contato</th>
                <td>{solicitacaoDados.telefoneContato}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_TelefoneDelivery
            ) !== -1 && (
              <tr>
                <th>Telefone Delivery</th>
                <td>{solicitacaoDados.telefoneDelivery}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
            ) !== -1 && (
              <tr>
                <th>Incluir logo do cliente na arte?</th>
                <td>{possuiLogo}</td>
              </tr>
            )}

            {cadastroItens.findIndex(
              (cadastroItem) =>
                cadastroItem.key === cadastroItemKeys.Dados_Rodape
            ) !== -1 && (
              <tr>
                <th>O cliente possui rodapé?</th>
                <td>{possuiRodape}</td>
              </tr>
            )}

            {solicitacaoDados.observacoesGerais &&
              solicitacaoDados.observacoesGerais.length && (
                <tr>
                  <th>Observação</th>
                  <td>{solicitacaoDados.observacoesGerais}</td>
                </tr>
              )}

            <tr>
              <th>Aprovador</th>
              <td>
                {solicitacao.materialComunicacao.definiAprovador
                  ? aprovador?.nome || ''
                  : aprovadorMidiasDigitais?.nome || ''}
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>
    );
  };

  const renderLayout = () => {
    if (!layoutArte.layoutArteId) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Layout</h4>
        <img src={layoutArte.urlImagem} className="img-fluid" />
        <hr />
      </div>
    );
  };

  const renderLogo = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
      ) === -1
    )
      return null;

    if (!cliente.urlLogo || !cliente.urlLogo.length) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Logo</h4>
        <img src={cliente.urlLogo} className="img-fluid" />
        <hr />
      </div>
    );
  };

  const renderRodape = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Rodape
      ) === -1
    )
      return null;

    return (
      <div className="resumo-informacoes">
        <h4>Rodapé</h4>
        {renderRodapeArquivo()}
        {renderRodapeEnderecos()}
        <hr />
      </div>
    );
  };

  const renderRodapeArquivo = () => {
    if (!possuiRodape) return null;

    if (!cliente.urlRodape || !cliente.urlRodape.length) {
      return null;
    }

    if (checkUrlImage(cliente.urlRodape)) {
      return <img src={cliente.urlRodape} className="img-fluid" />;
    } else {
      return (
        <a href={cliente.urlRodape} target="_blank" className="btn btn-primary">
          Baixar Rodapé
        </a>
      );
    }
  };

  const renderRodapeEnderecos = () => {
    // if (possuiRodape)
    //   return null;

    return (
      <>
        <table className="table">
          <tbody>
            <tr>
              <th>Forma de Pagamento</th>
              <td>{solicitacaoDados.formaPagamento}</td>
            </tr>
            {solicitacaoDados.outraFormaPagamento &&
              solicitacaoDados.outraFormaPagamento.length && (
                <tr>
                  <th>Forma de Pagamento</th>
                  <td>{solicitacaoDados.formaPagamento}</td>
                </tr>
              )}
            <tr>
              <th>Endereços</th>
              <td>
                {solicitacaoRodapes.map((solicitacaoRodape, index) => {
                  return (
                    <div key={index}>
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>CEP</th>
                            <td>{solicitacaoRodape.cep}</td>
                          </tr>
                          <tr>
                            <th>Endereço</th>
                            <td>{solicitacaoRodape.endereco}</td>
                          </tr>
                          <tr>
                            <th>Número</th>
                            <td>{solicitacaoRodape.numero}</td>
                          </tr>
                          <tr>
                            <th>Complemento</th>
                            <td>{solicitacaoRodape.complemento}</td>
                          </tr>
                          <tr>
                            <th>Bairro</th>
                            <td>{solicitacaoRodape.bairro}</td>
                          </tr>
                          <tr>
                            <th>Cidade</th>
                            <td>{(solicitacaoRodape.cidade || {}).nome}</td>
                          </tr>
                          <tr>
                            <th>Estado</th>
                            <td>{(solicitacaoRodape.cidade || {}).uf}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderEntregaDigital = () => {
    if (!tipoEntrega.entregaDigital) return null;

    return (
      <div className="resumo-informacoes">
        <h4>Entrega Digital</h4>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th>Nome </th>
              <td>{entregaDigital.nome}</td>
            </tr>
            <tr>
              <th>Email(s) </th>
              <td>{entregaDigital.email}</td>
            </tr>
            <tr>
              <th>Telefone </th>
              <td>{entregaDigital.telefone}</td>
            </tr>
          </tbody>
        </table>

        <hr />
      </div>
    );
  };

  const renderEntregaFisica = () => {
    return (
      <div className="resumo-informacoes">
        <h4>Entrega</h4>
        {renderEntregaCentroDistribuicao()}
        {renderEntregaLojaLoja()}
        {renderEntregaOutro()}
        <hr />
      </div>
    );
  };

  const renderEntregaCentroDistribuicao = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.centroDistribuicao) return null;

    return (
      <table className="table">
        <tbody>
          <tr>
            <th>CEP</th>
            <td>{cliente.cep}</td>
          </tr>
          <tr>
            <th>Endereço</th>
            <td>{cliente.logradouro}</td>
          </tr>
          <tr>
            <th>Número</th>
            <td>{cliente.numero}</td>
          </tr>
          <tr>
            <th>Complemento</th>
            <td>{cliente.complemento}</td>
          </tr>
          <tr>
            <th>Bairro</th>
            <td>{cliente.bairro}</td>
          </tr>
          <tr>
            <th>Cidade</th>
            <td>{cliente.cidade?.nome}</td>
          </tr>
          <tr>
            <th>Estado</th>
            <td>{cliente.cidade?.uf}</td>
          </tr>
          <tr>
            <th>Razão Social</th>
            <td>{cliente.razaoSocial}</td>
          </tr>
          <tr>
            <th>CNPJ</th>
            <td>{cliente.cnpj}</td>
          </tr>
          <tr>
            <th>Nome</th>
            <td>{entregaCentroDistribuicao.responsavelNome}</td>
          </tr>
          <tr>
            <th>Telefone</th>
            <td>{entregaCentroDistribuicao.responsavelTelefone}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{entregaCentroDistribuicao.responsavelEmail}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderEntregaLojaLoja = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.lojaLoja) return null;

    return (
      <div>
        {entregaLojasParticipantes.map((loja, index) => {
          return (
            <div key={index}>
              <table className="table">
                <tbody>
                  <tr>
                    <th>CEP</th>
                    <td>{loja.cep}</td>
                  </tr>
                  <tr>
                    <th>Endereço</th>
                    <td>{loja.logradouro}</td>
                  </tr>
                  <tr>
                    <th>Número</th>
                    <td>{loja.numero}</td>
                  </tr>
                  <tr>
                    <th>Complemento</th>
                    <td>{loja.complemento}</td>
                  </tr>
                  <tr>
                    <th>Bairro</th>
                    <td>{loja.bairro}</td>
                  </tr>
                  <tr>
                    <th>Cidade</th>
                    <td>{loja.cidade?.nome}</td>
                  </tr>
                  <tr>
                    <th>Estado</th>
                    <td>{loja.cidade?.uf}</td>
                  </tr>
                  <tr>
                    <th>Razão Social</th>
                    <td>{loja.razaoSocial}</td>
                  </tr>
                  <tr>
                    <th>CNPJ</th>
                    <td>{loja.cnpj}</td>
                  </tr>
                  <tr>
                    <th>Nome</th>
                    <td>{loja.responsavelNome}</td>
                  </tr>
                  <tr>
                    <th>Telefone</th>
                    <td>{loja.responsavelTelefone}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{loja.responsavelEmail}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
          );
        })}
      </div>
    );
  };

  const renderEntregaOutro = () => {
    if (tipoEntrega.nome !== entregaTipoNomes.outro) return null;

    return (
      <table className="table">
        <tbody>
          <tr>
            <th>CEP</th>
            <td>{entregaOutro.cep}</td>
          </tr>
          <tr>
            <th>Endereço</th>
            <td>{entregaOutro.logradouro}</td>
          </tr>
          <tr>
            <th>Número</th>
            <td>{entregaOutro.numero}</td>
          </tr>
          <tr>
            <th>Complemento</th>
            <td>{entregaOutro.complemento}</td>
          </tr>
          <tr>
            <th>Bairro</th>
            <td>{entregaOutro.bairro}</td>
          </tr>
          <tr>
            <th>Cidade</th>
            <td>{entregaOutro.cidade?.nome}</td>
          </tr>
          <tr>
            <th>Estado</th>
            <td>{entregaOutro.cidade?.uf}</td>
          </tr>
          <tr>
            <th>Razão Social</th>
            <td>{entregaOutro.razaoSocial}</td>
          </tr>
          <tr>
            <th>CNPJ</th>
            <td>{entregaOutro.cnpj}</td>
          </tr>
          <tr>
            <th>Nome</th>
            <td>{entregaOutro.responsavelNome}</td>
          </tr>
          <tr>
            <th>Telefone</th>
            <td>{entregaOutro.responsavelTelefone}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{entregaOutro.responsavelEmail}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderProdutos = () => {
    if (
      cadastroItens.findIndex(
        (cadastroItem) => cadastroItem.key === cadastroItemKeys.Dados_Logo
      ) === -1
    )
      return null;

    if (solicitacaoProdutos.length === 0)
      return (
        <div className="resumo-informacoes">
          <h4>Produtos</h4>
          <i>Sem produtos</i>
          <hr />
        </div>
      );

    return (
      <div className="resumo-informacoes resumo-grid-produtos">
        <h4>Produtos</h4>
        {solicitacaoProdutos.map((solicitacaoProduto, index) => {
          return (
            <div className="row row-striped" key={index}>
              <div className="col-lg-2">
                <div className="row">
                  <div className="col-lg-12">
                    {solicitacaoProduto.urlImagem &&
                      solicitacaoProduto.urlImagem.length && (
                        <img
                          className="img-fluid"
                          src={solicitacaoProduto.urlImagem}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Código</label>
                    {solicitacaoProduto.produto.codigo}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <label>Produto</label>
                    {solicitacaoProduto.produto.nome}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Descrição no Encarte</label>
                    {solicitacaoProduto.descricao}
                  </div>
                </div>

                {(solicitacaoProduto.solicitacaoProdutoDetalhamentos || [])
                  .length > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <label>Detalhes</label>
                      {(
                        (
                          solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                          []
                        ).map((x) => {
                          return solicitacaoProduto.produto?.produtoDetalhamentos?.find(
                            (detalhamento) =>
                              detalhamento.produtoDetalhamentoId ===
                              x.produtoDetalhamentoId
                          );
                        }) || []
                      ).map((detalhamento, index) => {
                        return (
                          <span key={index} className="d-block">
                            {detalhamento?.descricao}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-2">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Unidade</label>
                    {solicitacaoProduto.unidade}
                  </div>
                </div>
              </div>

              <div className="col-lg-2">
                <div className="row">
                  {!solicitacaoProduto.oferta && (
                    <div className="col-lg-12">
                      <label>Preço</label>
                      <span className="produto-preco">
                        {solicitacaoProduto.preco
                          ? `R$ ${getStringFromPrice(solicitacaoProduto.preco)}`
                          : 'Sem precificador'}
                      </span>
                    </div>
                  )}

                  {solicitacaoProduto.oferta && (
                    <div className="col-lg-12">
                      <label>Preço OFERTA</label>
                      <span className="produto-preco">
                        de R$ {getStringFromPrice(solicitacaoProduto.precoDe)}
                      </span>{' '}
                      <span className="produto-preco">
                        por R$ {getStringFromPrice(solicitacaoProduto.preco)}
                      </span>
                    </div>
                  )}
                </div>

                {solicitacaoProduto.destaque && (
                  <div className="row">
                    <div className="col-lg-12">
                      <label className="bg-primary text-white d-block text-center">
                        <i>Destacar</i>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <hr />
      </div>
    );
  };

  const [ico, setIcon] = useState(false);

  const mudarIcon = () => {
    if (ico === false) {
      setIcon(true);
    } else {
      setIcon(false);
    }
  };

  return (
    <div className="col-lg-12 resumo mb-4">
      <div className="resumo-header">
        <Accordion.Toggle
          onClick={mudarIcon}
          as={Card.Header}
          eventKey={accordionKey}
        >
          {solicitacao.solicitacaoId > 0 && (
            <span>Solicitação {solicitacao.solicitacaoId} - </span>
          )}{' '}
          {material?.nome}
          <div className="float-right">
            <FontAwesomeIcon
              id="icone"
              icon={ico !== true ? faAngleDown : faAngleUp}
            />
          </div>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={accordionKey}>
        <div className="resumo-body">
          {renderInformacoes()}

          {renderLayout()}

          {renderLogo()}

          {renderRodape()}

          {renderEntregaDigital()}

          {renderEntregaFisica()}

          {renderProdutos()}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default ResumoMaterial;
